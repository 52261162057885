/**
 * Narrative CSS styles for PictureBook Studio and Reader
 */

.grayed-out {
  background: #e7e7e7;
  color: transparent;
  transition: transform 1s;
}

.unindent {
  text-indent: 0;
}

p {
  font-size: 110%;
  line-height: 160%;
  text-indent: 20px;
  margin: 0;
  padding: 0;
  font-family: "Georgia";
}

h1, h2, h3, h4, h5, h6 {
  font-size: 110%;
  line-height: 160%;
  font-family: "Georgia";
}

.tox-editor-container {
  z-index: -10000
}

/* .pb-no-indent {
   margin: 0;
   text-indent: 0;
 }
 
 .pb-bold {
   color: violet;
 } */