.text-wrapper {
  font-family: Georgia;
  font-size: 19px;
  line-height: 1.5;
  margin: 0 30px;
  color: black;
  text-align: justify;
}

.pb-snippet {
  background: 'transparent';
  opacity: 1;
}